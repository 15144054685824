//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchGame, editGame, createGame, deleteGame } from '../../actions/gamesActions';
import { fetchGameMedias } from '../../actions/mediasActions';

import GameForm from '../forms/GameForm';
import history from '../../history';
import { io } from 'socket.io-client';
import { fetchGameItems } from '../../actions/itemsActions';
import SceneForm from '../forms/SceneForm';
import { bconfirm } from '../utils/ConfirmAlert';

const GameEdit = ({ dispatch, createGame, fetchGame, editGame, game, gameidToEdit, onCancel, onSave, showTitle, afterDelete }) => {
  // const skataFetch = () => {
  //     dispatch(fetchGame(gameidToEdit));
  // }
  useEffect(() => {
    if (gameidToEdit !== -1) {
      //skataFetch();
      dispatch(fetchGame(gameidToEdit));
      dispatch(fetchGameMedias(gameidToEdit)); // needed to allow showing the logo
      dispatch(fetchGameItems(gameidToEdit));
    }
  }, [gameidToEdit, fetchGame, dispatch]);
  // componentDidMount() {
  //     this.props.dispatch(this.props.fetchGame(this.props.match.params.id));
  // }
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    history.push('/'); // if no specific handler is used, redirect to home page to the game list
  };
  const handleDelete = async (id) => {
    const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
    if (result) {
      dispatch(deleteGame(id))
        .then(() => {
          if (afterDelete) {
            afterDelete();
            return;
          } else {
            history.push('/');
          }
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };
  const onSubmit = (formValues) => {
    if (gameidToEdit !== -1) {
      dispatch(editGame(gameidToEdit, formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push('/');
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    } else {
      dispatch(createGame(formValues))
        .then(() => {
          if (onSave) {
            onSave();
            return;
          } else {
            history.push('/');
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    }
  };
  // const testAddToTitle = (formValues) => {
  //     dispatch(editGame(gameidToEdit, {title:game.title+'aaaa'}, true));
  // };
  if (gameidToEdit !== -1 && !game) {
    return <div>Loading...</div>;
  }
  const defaultInitialValues = {
    lat: 35.51649359416815,
    lng: 24.01894116556346,
    radius: 1000,
    minAge: 12,
    description: 'A default description that needs to be edited....',
    playgroundtitle: 'Nowhare yet',
    capabilities: {
      seeBackPack: true,
      seeSkills: true,
      scanCodes: true,
      typeCodes: true,
      seeDirections: true,
      seeTasks: true,
    },
    playingPeriod: {
      start: new Date().toISOString(),
      timezone: 'Europe/Athens',
    },
  };

  return (
    <div className=''>
      <GameForm enableReinitialize={true} handleCancel={handleCancel} handleDelete={handleDelete} initialValues={gameidToEdit !== -1 ? game : defaultInitialValues} onSubmit={onSubmit} />
      {
        // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { game: state.games[ownProps.gameidToEdit] };
};
export default connect(mapStateToProps, (dispatch) => ({ dispatch, fetchGame: fetchGame, editGame: editGame, createGame: createGame }))(GameEdit);
